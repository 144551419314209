import React from 'react';
import { useIntl } from 'react-intl';
import { Container } from 'reactstrap';
import classNames from 'classnames';
import { DownloadButtons } from '../../../components/DownloadButtons';
import Antelope2 from '../../../../public/statics/svgs/antelope-2.svg';

import style from './IndexTop.module.scss';
import { NavbarComponent } from '../../../layouts/Navbar';

export const IndexTop: React.FC<{ rightContent?: React.ReactNode }> = React.memo(({ rightContent }) => {
  const intl = useIntl();
  return (
    <div className={classNames(style.wrapper)}>
      <div className={classNames('position-relative d-flex flex-column', style.container)}>
        <NavbarComponent dark />
        <Container className="flex-grow-1 d-flex flex-column justify-content-center align-items-center">
          <div className="align-items-center d-flex flex-row">
            <div className="mr-n2 d-none d-lg-flex">
              <Antelope2 style={{ width: '100%' }} />
            </div>
            <div className="d-flex flex-column align-items-center align-items-lg-start">
              <div className="d-flex flex-row">
                <div className="mr-n2 d-flex d-lg-none">
                  <Antelope2 style={{ width: 120 }} />
                </div>
                <div className="d-flex flex-row flex-wrap flex-lg-nowrap justify-content-center">
                  <div>
                    <h1
                      className={classNames(
                        'text-heading text-shadow-primary-opacity mb-2 mb-md-3 mt-5 text-black',
                        style.header
                      )}
                    >
                      {intl.formatMessage(
                        {
                          id: 'web.home.heading.title',
                          defaultMessage: 'Wildly fun <b>team building experiences</b>hosted by you'
                        },
                        {
                          b: (children: any) => <div>{children}</div>
                        }
                      )}
                    </h1>

                    <ul className={style.description}>
                      <li>
                        {intl.formatMessage(
                          {
                            id: 'web.home.heading.features.1',
                            defaultMessage: '<b>Jeopardy</b> & <b>Family Feud</b> like games'
                          },
                          {
                            b: (children: any) => <span className="text-primary">{children}</span>
                          }
                        )}
                      </li>
                      <li>
                        {intl.formatMessage({
                          id: 'web.home.heading.features.2',
                          defaultMessage: 'Tens of different questions & answers'
                        })}
                      </li>
                      <li>
                        {intl.formatMessage({
                          id: 'web.home.heading.features.3',
                          defaultMessage: 'Your own branding'
                        })}
                      </li>
                      <li>
                        {intl.formatMessage({
                          id: 'web.home.heading.features.4',
                          defaultMessage: 'Customizable questions & answers'
                        })}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-start flex-column mt-5">
                <div>
                  <DownloadButtons onDarkBg />
                </div>
              </div>
            </div>
            {rightContent !== undefined ? <div className="d-none d-md-block">{rightContent}</div> : null}
          </div>
        </Container>
      </div>
    </div>
  );
});
